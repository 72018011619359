import LoginService from '../common/LoginService';
import DirectoryService from '../directory/DirectoryService';
import { format } from 'date-fns';
const transformDateWithFormat = (britDate, formatStyle) => {
  const dateString = britDate;
  const date = new Date(dateString);
  const formattedDate = format(date, formatStyle);
  return formattedDate;
};

const parseDateTime = dateTime => {
  let dateTimeString = dateTime;
  if (dateTime instanceof Date) {
    dateTimeString = dateTime.toDateString();
  }
  // Split the date and time parts
  const [datePart, timePart] = dateTimeString.split(' ');

  // Extract day, month, and year from the date part
  const [day, month, year] = datePart.split('/').map(Number);

  // Extract hours and minutes from the time part
  let [hours, minutes] = timePart.split(':');
  minutes = minutes.slice(0, 2); // Remove the AM/PM suffix
  const ampm = timePart.slice(-2); // Get AM or PM

  // Convert hours to 24-hour format
  if (ampm === 'PM' && hours !== '12') {
    hours = Number(hours) + 12;
  } else if (ampm === 'AM' && hours === '12') {
    hours = 0;
  }

  // Format to ISO string
  const isoString = new Date(
    year,
    month - 1,
    day,
    hours,
    minutes
  ).toISOString();

  return isoString;
};

// Example usage
const dateTimeString = '25/11/2024 3:00 PM';
const isoString = parseDateTime(dateTimeString);
console.log(isoString); // Output: "2024-11-25T15:00:00.000Z"

const EventService = {
  saveAzureCalendarEvent: async (event, instance, accounts) => {
    const accessTokenRequest = {
      scopes: ['Calendars.ReadWrite'], // Permissions to access calendar events
      account: accounts[0],
      prompt: 'consent' // Force consent to ensure all scopes are granted
    };
    const managerColour = 'primary';
    const response = await instance.acquireTokenSilent(accessTokenRequest);
    const token = response.accessToken; // Return the access token
    console.log(event);
    let attendees = [];
    if (event.attendees !== undefined) {
      attendees = event.attendees.map(a => ({
        type: 'required',
        emailAddress: { name: a.label, address: a.key }
      }));
    }

    let eventData = {
      subject: event.title,
      attendees: attendees,
      body: {
        contentType: 'HTML',
        content: event.description
      },
      start: {
        dateTime: event.start, // Date and time format: 'YYYY-MM-DDTHH:mm:ss'
        timeZone: 'UTC'
      },
      end: {
        dateTime: event.end,
        timeZone: 'UTC'
      },
      location: {
        displayName: 'Online Meeting'
      }
    };

    if (token) {
      let method = 'POST';
      let url = 'https://graph.microsoft.com/v1.0/me/events';
      // Update event
      if (event.id !== undefined) {
        eventData = {
          ...eventData,
          id: event.id
        };
        method = 'PATCH';
        url = `https://graph.microsoft.com/v1.0/me/events/${eventData.id}`;
      }
      console.log(eventData);
      console.log(url);
      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(eventData)
        });

        if (!response.ok) throw new Error('Failed to create event');

        const data = await response.json();
        const updatedData = {
          ...data,
          classNames: `border border-2 border-primary bg-100`,
          display: 'background',
          color: managerColour
        };

        console.log('Event created:', updatedData); // Log the created event details
        return updatedData;
      } catch (error) {
        console.error('Error creating calendar event:', error);
      }
    }
  },
  saveManagementEvent: async function (event) {
    console.log(event);
    try {
      const profile = DirectoryService.retrieveLoggedProfile();
      const eventWithProfile = {
        ...event,
        profileIdentifier: profile.identifier,
        label: event.label.label
        /*start: parseDateTime(event.start),
        startTime: parseDateTime(event.start),
        end: parseDateTime(event.end),
        endTime: parseDateTime(event.end)*/
      };

      let url = '/api/event';
      let method = 'POST';

      if (eventWithProfile.identifier !== undefined) {
        method = 'PUT';
        url = '/api/event/' + eventWithProfile.identifier;
        eventWithProfile.attendeesValues = eventWithProfile.attendees;
        delete eventWithProfile.attendees;
        delete eventWithProfile.profile.children;
        delete eventWithProfile.profile;
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventWithProfile)
      });

      if (response.ok) {
        let newEvent = await response.json();
        console.log(newEvent);
        const managerColour = 'primary';
        //const childrenColour = 'success';
        const managementEvent = {
          extendedProps: {
            status: newEvent.status,
            profile: newEvent.profile,
            identifier: newEvent.identifier,
            profileIdentifier: newEvent.profileIdentifier,
            editButtons: true
          },
          id: newEvent.identifier,
          title: newEvent.title,
          start: transformDateWithFormat(newEvent.start, 'yyyy-MM-dd'),
          startTime: transformDateWithFormat(newEvent.start, 'dd MMM, yyyy'),
          end: transformDateWithFormat(newEvent.end, 'yyyy-MM-dd'),
          endTime: transformDateWithFormat(newEvent.end, 'dd MMM, yyyy'),

          classNames: `border border-2 border-primary bg-100`,
          display: 'background',
          color: managerColour
        };

        console.log('Event has been created', managementEvent);

        return managementEvent; // Return the response from the server
      } else {
        throw new Error('Error saving Event');
      }
    } catch (error) {
      console.log('Error saving Event', error);
      throw error; // Throw the error to handle it outside the function if needed
    }
  },
  retrieveManagementEventByIdentfier: async function (identifier) {
    try {
      const response = await fetch('/api/event/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  retrieveMSAzureEvent: async function (instance, accounts, eventId) {
    const accessTokenRequest = {
      scopes: ['Calendars.ReadWrite'], // Permissions to access calendar events
      account: accounts[0],
      prompt: 'consent' // Force consent to ensure all scopes are granted
    };

    const response = await instance.acquireTokenSilent(accessTokenRequest);
    const token = response.accessToken; // Return the access token

    if (token) {
      try {
        const response = await fetch(
          `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`, // Use the token for authorization
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch event: ${response.status}`);
        }

        const event = await response.json(); // Parse the event details
        console.log('Event Details:', event); // Log the event details
        const eventUpdated = {
          ...event,
          id: event.id,
          title: event.subject,
          start: transformDateWithFormat(
            event.start.dateTime,
            'dd/MM/yyyy hh:mm a'
          ),
          startTime: transformDateWithFormat(
            event.start.dateTime,
            'dd/MM/yyyy hh:mm a'
          ),
          end: transformDateWithFormat(
            event.end.dateTime,
            'dd/MM/yyyy hh:mm a'
          ),
          endTime: transformDateWithFormat(
            event.end.dateTime,
            'dd/MM/yyyy hh:mm a'
          ),
          attendees: event.attendees.map(a => ({
            key: a.emailAddress.address,
            label: a.emailAddress.name
          })),
          url: event.webLink,
          description: event.body.content
        };

        return eventUpdated; // Return the event object
      } catch (error) {
        console.error('Error fetching specific event:', error);
      }
    }
  },
  retrieveManagementEventsForHome: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch('/api/event/profile/' + profile.identifier);
      const managerColour = 'primary';
      const childrenColour = 'success';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          attendees: h.attendees,
          url: h.url,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title: h.title,
        start: transformDateWithFormat(h.start, 'yyyy-MM-dd'),
        startTime: transformDateWithFormat(h.start, 'dd MMM, yyyy'),
        end: transformDateWithFormat(h.end, 'yyyy-MM-dd'),
        endTime: transformDateWithFormat(h.end, 'dd MMM, yyyy'),

        classNames: `border border-2 border-primary bg-100`,
        display: 'background',
        color:
          h.authorizedBy === profile.identifier ? childrenColour : managerColour
      }));

      //const microsoftEvents = await this.retrieveMicrosoftEvents();
      console.log(events);
      const sortedEvents = events.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );

      return sortedEvents;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  retrieveManagementEvents: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch('/api/event/profile/' + profile.identifier);
      const managerColour = 'primary';
      const childrenColour = 'success';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          attendees: h.attendees,
          url: h.url,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title: h.title,
        start: transformDateWithFormat(h.start, 'yyyy-MM-dd'),
        startTime: transformDateWithFormat(h.start, 'dd MMM, yyyy'),
        end: transformDateWithFormat(h.end, 'yyyy-MM-dd'),
        endTime: transformDateWithFormat(h.end, 'dd MMM, yyyy'),
        color:
          h.authorizedBy === profile.identifier ? childrenColour : managerColour
      }));

      console.log(events);
      return events;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  retrieveMicrosoftEvents: async function (instance, accounts) {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const token = await LoginService.getAzureAccessToken(instance, accounts);
    const managerColour = 'primary';
    const childrenColour = 'success';
    if (token) {
      try {
        const response = await fetch(
          'https://graph.microsoft.com/v1.0/me/events',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (!response.ok) throw new Error('Failed to fetch events');

        const data = await response.json();
        data.value.map(h => {
          console.log(h);
        });
        const events = data.value.map(h => ({
          extendedProps: {
            status: h.status,
            profile: h.profile,
            identifier: h.id,
            //authorizedBy: h.authorizedBy,
            //profileIdentifier: h.profileIdentifier,
            //attendees: h.attendees,
            attendees: h.attendees.map(a => ({
              key: a.emailAddress.address,
              label: a.emailAddress.name
            })),
            url: h.webLink,
            editButtons: h.isOrganizer === true //|| h.authorizedBy === profile.identifier
          },
          id: h.id,
          title: h.subject,
          start: transformDateWithFormat(h.start.dateTime, 'yyyy-MM-dd'),
          startTime: transformDateWithFormat(h.start.dateTime, 'dd MMM, yyyy'),
          end: transformDateWithFormat(h.end.dateTime, 'yyyy-MM-dd'),
          endTime: transformDateWithFormat(h.end.dateTime, 'dd MMM, yyyy'),
          color:
            h.authorizedBy === profile.identifier
              ? childrenColour
              : managerColour
        }));
        console.log('Events:', events); // Log the events
        return events;
      } catch (error) {
        console.error('Error fetching calendar events:', error);
      }
    }
    return [];
  },
  async archiveEvent(identifier) {
    try {
      let url = '/api/event/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newGroups = await response.json();
        console.log('Groups has been deleted:', newGroups);
      }
    } catch (error) {
      console.log('Error deleting Groups', error);
    }
  },
  async archiveAzureCalendarEvent(eventId, instance, accounts) {
    try {
      const token = await LoginService.getAzureAccessToken(instance, accounts);
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to delete event: ${response.status}`);
      }

      console.log('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  }
};

export default EventService;
