import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import defaultAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import LoginService from 'components/app/common/LoginService';
import { useEffect } from 'react';
import DirectoryService from 'components/app/directory/DirectoryService';
import { Form } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
const ProfileDropdown = () => {
  const { instance, accounts } = useMsal();
  const [picture, setPicture] = useState();
  const [profileLink, setProfileLink] = useState();
  const [demoMode, setDemoMode] = useState(
    localStorage.getItem('bstDemoMode') === 'true'
  );
  const switchDemoMode = () => {
    const newValue = !demoMode;
    localStorage.setItem('bstDemoMode', newValue);
    setDemoMode(newValue);
    console.log(localStorage.getItem('bstDemoMode'));
  };
  useEffect(() => {
    const profile = DirectoryService.retrieveLoggedProfile();
    console.log(profile.avatar);
    const url = profile.avatar !== null ? profile.avatar : defaultAvatar;
    console.log(url);
    setPicture(url);
    const profileLink = '/social/directory/user-details/' + profile.identifier;
    setProfileLink(profileLink);
  }, [picture]);
  const logout = async () => {
    try {
      await LoginService.logout(instance, accounts); // No need for .then(), await handles the success flow
      window.location.href = '/'; // Redirect after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={picture} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={profileLink}>
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Divider />
          {DirectoryService.isLoggedByHRAdmin() && (
            <Dropdown.Item as={Link} to="/user/settings">
              Settings
            </Dropdown.Item>
          )}
          <Dropdown.ItemText>
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Demo"
              checked={demoMode}
              onChange={() => switchDemoMode()}
            />
          </Dropdown.ItemText>
          <Dropdown.Item as={Link} onClick={logout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
