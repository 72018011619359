// src/AuthCallbackWithMicrosoft.js
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory

const AuthCallbackWithMicrosoft = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate(); // This replaces useHistory()

  useEffect(() => {
    if (accounts.length > 0) {
      // If the user is already logged in, redirect to the dashboard
      console.log(accounts[0].username);
      console.log('/dashboard');
    } else {
      // Handle the MSAL redirect and token acquisition
      instance
        .handleRedirectPromise()
        .then(response => {
          if (response) {
            // Successful login, redirect to dashboard
            console.log(response);
            console.log('/dashboard');
          }
        })
        .catch(error => {
          console.error('Login failed:', error);
        });
    }
  }, [instance, accounts, navigate]); // useNavigate replaces useHistory here

  return <div>Redirecting...</div>; // Loading message while processing redirection
};

export default AuthCallbackWithMicrosoft;
