import React, { useEffect, useState } from 'react';
//import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';
//import team1 from 'assets/img/team/3.jpg';
import AbsenceThisYear from './AbsenceThisYear';
import SharedFiles from './SharedFiles';
import ActiveUsers from './ActiveUsers';
import CalendarManagement from 'components/app/management-events/CalendarManagement';
import ToDoList from './ToDoList';
import HolidayService from '../../app/holidays/HolidayService';
import EventService from '../../app/management-events/EventService';
import DirectoryService from 'components/app/directory/DirectoryService';
import DocumentService from 'components/app/documents/DocumentService';
import defaultAvatar from 'assets/img/team/avatar.png';
import AnnoucementWidget from 'components/app/annoucements/AnnoucementWidget';
import InternalVacanciesWidget from 'components/app/rolepositions/InternalVacanciesWidget';
import { useMsal } from '@azure/msal-react';

const HRDashboard = () => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [outOfOfficeToday, setOutOfOfficeToday] = useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [absencesByUsers, setAbsencesByUsers] = useState([]);
  const [showAbsences, setShowAbsences] = useState(true);
  const [managementEvents, setManagementEvents] = useState([]);
  const [initialEvents, setInitialEvents] = useState([]);
  const [profile, setProfile] = useState();
  const { instance, accounts } = useMsal();
  const [displayDashboard, setDisplayDashboard] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  useEffect(() => {
    console.log(managementEvents);
    console.log(initialEvents);

    HolidayService.retrieveAbsences().then(data => {
      setAbsencesByUsers(data);
      if (absencesByUsers.length > 0) {
        setShowAbsences(true);
      }
    });

    const profile = DirectoryService.retrieveLoggedProfile();
    setProfile(profile);

    DirectoryService.retrieveUsersByAccount()
      .then(users => {
        const activeUsers = users.map(u => ({
          id: u.identifier,
          name: u.firstName + ' ' + u.lastName,
          avatar: {
            status: u.online === true ? 'online' : 'offline',
            src: u.avatar || defaultAvatar,
            size: '2xl'
          }
        }));
        setActiveUsers(activeUsers);
      })
      .catch(error => this.setState({ error: error.message }));

    DirectoryService.retrieveOutOfOfficeToday()
      .then(users => {
        const outOfOfficeToday = users.map(u => ({
          id: u.identifier,
          name: u.firstName + ' ' + u.lastName,
          avatar: {
            status: u.status || 'offline',
            src: u.avatar || defaultAvatar,
            size: '2xl'
          }
        }));
        setOutOfOfficeToday(outOfOfficeToday);
      })
      .catch(error => this.setState({ error: error.message }));
    DocumentService.retrieveDocumentsByProfile().then(docs => {
      const sharedDocs = docs.map(d => ({
        id: d.identifier,
        img: d.img,
        name: d.name,
        time: d.dateCreated,
        user: d.profile.email,
        border: true
      }));
      setSharedDocs(sharedDocs);
    });
    console.log(managementEvents);
    const fetchEvents = async () => {
      const events = await EventService.retrieveManagementEvents();
      // const holidayRequests = await HolidayService.retrieveHolidayRequests();
      let msEvents = await EventService.retrieveMicrosoftEvents(
        instance,
        accounts
      );
      if (msEvents === undefined) {
        msEvents = [];
      }
      // Merge the two arrays using the spread operator
      const mergedEvents = [...events, ...msEvents];
      setInitialEvents(mergedEvents);
      setManagementEvents(mergedEvents);
      setShowCalendar(true);
    };
    fetchEvents();
    setDisplayDashboard(true);
  }, []);
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={4}>
          {displayDashboard &&
            showAbsences &&
            null !== profile &&
            undefined !== profile.contract && (
              <AbsenceThisYear
                data={HolidayService.getValuesForAbsencesChart(
                  profile,
                  absencesByUsers
                )}
                radius={['100%', '87%']}
              />
            )}
        </Col>
        <Col md={6} xxl={8}>
          <AnnoucementWidget />
        </Col>
      </Row>
      <Row className="g-3">
        <Col xxl={8}>
          {showCalendar && <CalendarManagement data={managementEvents} />}
        </Col>
        <Col xxl={4}>
          <ActiveUsers
            title={'Active users'}
            className="h-100"
            users={activeUsers}
          />
        </Col>
      </Row>
      <br></br>
      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <ToDoList />
        </Col>
        <Col lg={5} xl={4}>
          <SharedFiles files={sharedDocs.slice(0, 5)} className="h-lg-100" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <InternalVacanciesWidget />
        </Col>
        <Col lg={5} xl={4}>
          <ActiveUsers
            className="h-40"
            title={'Out of office today'}
            users={outOfOfficeToday}
          />
        </Col>
      </Row>
    </>
  );
};

export default HRDashboard;
