import React, { useContext } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';
import HolidayService from '../holidays/HolidayService';
const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </Flex>
);

const CalendarHolidayModal = ({
  setIsOpenEventModal,
  isOpenEventModal,
  modalEventContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };
  const approveHoliday = () => {
    HolidayService.approveHoliday(identifier).then(() => {
      console.log(identifier);
      setStatusKey('APPROVED');
      setIsOpenEventModal(!isOpenEventModal);
    });
  };
  const rejectHoliday = () => {
    HolidayService.rejectHoliday(identifier).then(() => {
      console.log(identifier);
      setStatusKey('REJECTED');
      setIsOpenEventModal(!isOpenEventModal);
    });
  };
  const { title, end, start } = isOpenEventModal && modalEventContent.event;
  const {
    description,
    location,
    organizer,
    schedules,
    profile,
    identifier,
    status,
    editButtons,
    attendees,
    url
  } = isOpenEventModal && modalEventContent.event.extendedProps;
  const isUUID = value => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  };
  const setStatusKey = useState(status)[1];

  let updatedAttendees = attendees;

  if (!isUUID(identifier) && undefined !== attendees) {
    console.log(attendees);
    const azureAttendees = attendees.map(a => ({
      firstName: a.label,
      lastName: ''
    }));
    updatedAttendees = azureAttendees;
  }

  return (
    <Modal
      show={isOpenEventModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-card border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
          {organizer && (
            <p className="mb-0 fs--1 mt-1 fw-normal">
              by <a href="#!">{organizer}</a>
            </p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-card pb-card pt-1 fs--1">
        {description && (
          <EventModalMediaContent
            icon="align-left"
            heading="Description"
            content={description}
          />
        )}
        {(end || start) && (
          <EventModalMediaContent icon="calendar-check" heading="Date and Time">
            <p className="mb-1">
              {dayjs(start).format('dddd, MMMM DD, YYYY, hh:mm A')}
              {end && (
                <>
                  -<br />
                  {dayjs(end).format('dddd, MMMM DD, YYYY, hh:mm A')}
                </>
              )}
            </p>
          </EventModalMediaContent>
        )}
        {profile && (
          <EventModalMediaContent icon="user" heading="Organizer">
            <div
              className="mb-1"
              dangerouslySetInnerHTML={{
                __html: profile.firstName + ' ' + profile.lastName
              }}
            />
          </EventModalMediaContent>
        )}
        {attendees && (
          <EventModalMediaContent icon="users" heading="Attendees">
            {updatedAttendees.map((attendee, index) => (
              <div
                key={index}
                className="mb-1"
                dangerouslySetInnerHTML={{
                  __html: attendee.firstName + ' ' + attendee.lastName
                }}
              />
            ))}
          </EventModalMediaContent>
        )}
        {url && (
          <EventModalMediaContent icon="link" heading="Conference link">
            <a href={url} target="_blank" rel="noreferrer">
              <div
                className="mb-1"
                dangerouslySetInnerHTML={{
                  __html: url.length > 50 ? url.substring(0, 49) + '...' : url
                }}
              />
            </a>
          </EventModalMediaContent>
        )}
        {location && (
          <EventModalMediaContent icon="map-marker-alt" heading="Location">
            <div
              className="mb-1"
              dangerouslySetInnerHTML={{ __html: location }}
            />
          </EventModalMediaContent>
        )}
        {status && (
          <EventModalMediaContent icon="tag" heading="Status">
            <div
              className="mb-1"
              dangerouslySetInnerHTML={{ __html: status }}
            />
          </EventModalMediaContent>
        )}
        {schedules && (
          <EventModalMediaContent icon="clock" heading="Schedule">
            <ul className="list-unstyled timeline mb-0">
              {schedules.map((schedule, index) => (
                <li key={index}>{schedule.title}</li>
              ))}
            </ul>
          </EventModalMediaContent>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-light px-card border-top-0">
        <Button
          as={Link}
          to={'/management-events/' + identifier + '/edit'}
          variant="falcon-default"
          size="sm"
        >
          <FontAwesomeIcon icon="pencil-alt" className="fs--2 me-2" />
          <span>Edit</span>
        </Button>
        {status === 'REQUESTED' && editButtons && (
          <>
            <Button
              as={Button}
              onClick={approveHoliday}
              variant="falcon-primary"
              size="sm"
            >
              <span>Approve request</span>
              <FontAwesomeIcon icon="thumbs-up" className="fs--2 ms-1" />
            </Button>
            <Button
              as={Button}
              onClick={rejectHoliday}
              variant="falcon-primary"
              size="sm"
            >
              <span>Reject request</span>
              <FontAwesomeIcon icon="thumb-down" className="fs--2 ms-1" />
            </Button>
          </>
        )}
        <Button
          as={Link}
          to={'/management-events/' + identifier}
          variant="falcon-primary"
          size="sm"
        >
          <span>See more details</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarHolidayModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  modalEventContent: PropTypes.object.isRequired
};

export default CalendarHolidayModal;
