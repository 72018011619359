import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import EquipmentService from './EquipmentService';
import DirectoryService from '../directory/DirectoryService';
import schema from './jsonSchema';
const jsonSchema = schema;
const mergedJsonSchema = (jsonSchema, users) => {
  return jsonSchema.map(field => {
    if (field.name === 'profile') {
      return {
        ...field,
        options: users
      };
    }

    return field;
  });
};
const EquipmentForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [users, setUsers] = useState();

  useEffect(async () => {
    if (undefined !== identifier) {
      EquipmentService.retrieveEquipment(identifier)
        .then(data => {
          DirectoryService.retrieveProfile(data.profileIdentifier).then(p => {
            const profile = {
              label: p.firstName + ' ' + p.lastName,
              value: p.identifier
            };
            const updatedData = {
              ...data,
              profile: profile
            };
            setData(updatedData);
          });
        })
        .catch(error => console.log(error));
    }
    const usersByAccount = await DirectoryService.retrieveUsersByAccount();
    const mappedUsers = usersByAccount.map(u => ({
      value: u.identifier,
      label: u.firstName + ' ' + u.lastName
    }));

    setUsers(mappedUsers);
  }, [identifier]);

  const handleEquipmentFormSubmit = formData => {
    EquipmentService.saveEquipment(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Equipment',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Equipment');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator('Create Equipment', '', []);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Equipment  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleEquipmentFormSubmit}
              users={users}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit, users }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, users),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any,
  users: PropTypes.any
};
export default EquipmentForm;
