import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../../common/FormUtils';
import useHeaderGenerator from '../../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../../common/FormModal';
import EventService from '../EventService';
import DirectoryService from 'components/app/directory/DirectoryService';
import schema from './jsonSchema';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
const jsonSchema = schema;

const mergedJsonSchema = (jsonSchema, users, labels) => {
  const attendees = users;
  return jsonSchema.map(field => {
    if (field.name === 'attendees') {
      return {
        ...field,
        options: attendees
      };
    }
    if (field.name === 'label') {
      return {
        ...field,
        options: labels
      };
    }
    return field;
  });
};
const convertToMilliseconds = dateTimeString => {
  // Parse the date and time string
  const [datePart, timePart] = dateTimeString.split(' ');
  const [day, month, year] = datePart.split('/').map(Number);

  // Extract hours, minutes, and AM/PM
  let [hours, minutes] = timePart.split(':');
  minutes = parseInt(minutes.slice(0, 2), 10); // Extract the numerical part of minutes
  const ampm = timePart.slice(-2); // AM or PM

  // Convert hours to 24-hour format
  hours = parseInt(hours, 10);
  if (ampm === 'PM' && hours !== 12) {
    hours += 12;
  } else if (ampm === 'AM' && hours === 12) {
    hours = 0;
  }

  // Create a Date object using UK timezone
  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, 0));

  // Convert to milliseconds
  return date.getTime();
};
const ManagementEventForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [labels, setLabels] = useState([]);

  const formRef = useRef(null);
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const isUUID = value => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  };
  useEffect(async () => {
    try {
      if (identifier) {
        if (isUUID(identifier)) {
          EventService.retrieveManagementEventByIdentfier(identifier)
            .then(data => {
              const attendees = data.attendees.map(u => ({
                label: u.firstName + ' ' + u.lastName,
                value: u.identifier
              }));
              const label = [{ label: data.label, value: data.label }];
              const allDay = data.allDay === true ? 'on' : 'off';
              const updatedData = {
                ...data,
                label: label,
                attendees: attendees,
                allDay: allDay
              };

              setData(updatedData);
            })
            .catch(error => console.log(error));
        } else {
          EventService.retrieveMSAzureEvent(instance, accounts, identifier)
            .then(data => {
              const label = [{ label: data.label, value: data.label }];
              const allDay = data.allDay === true ? 'on' : 'off';
              const updatedData = {
                ...data,
                label: label,
                allDay: allDay,
                start: convertToMilliseconds(data.start),
                end: convertToMilliseconds(data.end)
              };
              console.log(updatedData);
              setData(updatedData);
            })
            .catch(error => console.log(error));
        }
      }
      const usersByAccount = await DirectoryService.retrieveUsersByAccount();
      const mappedUsers = usersByAccount.map(u => ({
        value: u.identifier,
        label: u.firstName + ' ' + u.lastName
      }));
      if (undefined !== mappedUsers) {
        setUsers(mappedUsers);
      }

      const labels = [
        {
          label: 'Business',
          value: 'Business'
        },
        {
          label: 'Important',
          value: 'Important'
        },
        {
          label: 'Personal',
          value: 'Personal'
        },
        {
          label: 'Must Attend',
          value: 'Must Attend'
        }
      ];
      setLabels(labels);
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  const handleManagementEventFormSubmit = formData => {
    const allDay = formData.allDay === 'on' ? true : false;
    let updatedFormData = {
      ...formData,
      allDay: allDay
    };
    if (accounts.length > 0) {
      console.log(identifier);
      if (identifier !== undefined) {
        updatedFormData = {
          ...updatedFormData,
          id: identifier
        };
      }
      EventService.saveAzureCalendarEvent(updatedFormData, instance, accounts)
        .then(response => {
          console.log(response);
          setModalShow(true);
        })
        .catch(error => console.log(error));
    } else {
      EventService.saveManagementEvent(updatedFormData)
        .then(response => {
          console.log(response);
          setModalShow(true);
        })
        .catch(error => console.log(error));
      console.log('Event saved successfully:', event);
    }
  };

  const buttonsConf = [
    {
      name: 'Save Event',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        if (formRef.current) {
          formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          );
        }
        console.log('Save ManagementEvent');
      }
    }
  ];
  const archiveButton = {
    name: 'Archive',
    type: 'button',
    variant: 'warning',
    onClick: () => {
      console.log('Archive event');
      if (accounts.length > 0) {
        EventService.archiveAzureCalendarEvent(
          identifier,
          instance,
          accounts
        ).then(response => {
          console.log(response);
          navigate('/app/calendar');
        });
      } else {
        EventService.archiveEvent(identifier)
          .then(data => {
            console.log(data);
            navigate('/app/calendar');
          })
          .catch(error => console.log(error));
      }
    }
  };
  if (DirectoryService.isLoggedByHRAdmin() === true) {
    buttonsConf.push(archiveButton);
  }
  const headerGenerated = useHeaderGenerator('Event', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Event has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleManagementEventFormSubmit}
              users={users}
              labels={labels}
              formRef={formRef}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({
  data,
  buttonsConf,
  onSubmit,
  users,
  labels,
  formRef
}) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, users, labels),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      ref={formRef} // This line assigns the form element to the formRef
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  formRef: PropTypes.any,
  labels: PropTypes.any,
  onSubmit: PropTypes.any,
  users: PropTypes.any
};
export default ManagementEventForm;
