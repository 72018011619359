import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';

// Import MSAL for authentication
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig'; // Assuming you've created the authConfig.js file

// Initialize MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    {/* Wrap Main with MSAL Provider */}
    <MsalProvider instance={msalInstance}>
      <Main>
        <App />
      </Main>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('main') // 'main' should match your DOM element ID
);
