// src/authConfig.js
export const msalConfig = {
  auth: {
    clientId: '9c104d8d-4697-485a-b515-afa04d8960af',
    redirectUri:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/authentication/azure/callback'
        : 'https://hr.codersden.uk/authentication/azure/callback'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set to true if you're having issues on IE11 or Edge
  }
};

export const loginRequest = {
  scopes: ['User.Read', 'Calendars.ReadWrite']
};
