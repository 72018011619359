const GroupService = {
  async saveGroup(formData) {
    try {
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;
      let url = '/api/group';
      let method = 'POST';
      if (formData.identifier !== undefined) {
        url = '/api/group/' + formData.identifier;
        method = 'PUT';
      }
      const type = formData.type.value || formData.type[0].value;
      const updatedFormData = {
        ...formData,
        type: type,
        accountIdentifier: accountIdentifier,
        membersValues: formData.members
      };
      delete updatedFormData.members;
      console.log(updatedFormData);
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormData)
      });
      if (response.ok) {
        const newGroups = await response.json();
        console.log('Groups has been updated:', newGroups);
      }
    } catch (error) {
      console.log('Error saving Groups', error);
    }
  },
  async retrieveAllGroupsByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch('/api/group/account/' + accountIdentifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Groups', error);
      throw error;
    }
  },
  async retrieveGroup(identifier) {
    try {
      const response = await fetch('/api/group/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Groups', error);
      throw error;
    }
  },
  async deleteGroup(identifier) {
    try {
      let url = '/api/group/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newGroups = await response.json();
        console.log('Groups has been deleted:', newGroups);
      }
    } catch (error) {
      console.log('Error deleting Groups', error);
    }
  }
};
export default GroupService;
