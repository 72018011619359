import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from 'components/app/common/FormUtils';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import useButtonsGenerator from 'components/app/common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from 'components/app/common/FormModal';
import DirectoryService from '../DirectoryService';

import schema from './jsonSchema';
import { validate } from 'uuid';
const jsonSchema = schema;

const PasswordSettingsForm = ({ profile }) => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState('');
  const [setShowError, showError] = useState(false);
  useEffect(() => {
    setData(profile);
  }, [identifier]);
  const handlePasswordSettingsFormSubmit = formData => {
    const updatedFormData = { identifier: identifier, ...formData };
    setError('');
    DirectoryService.savePassword(updatedFormData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => {
        console.log(error);
        setError(error.message);
      });
  };

  const updatePasswordBtn = {
    name: 'Update password',
    type: 'submit',
    variant: 'info',
    onClick: () => {
      console.log('Update password');
    }
  };
  const sendRequestPasswordBtn = {
    name: 'Send password request to user',
    type: 'button',
    variant: 'success',
    onClick: () => {
      DirectoryService.sendRequestChangePassword(profile.identifier)
        .then(response => console.log(response))
        .catch(error => console.log(error));
    }
  };

  const headerGenerated = useHeaderGenerator('Password settings', ' ', []);
  const buttonsConf = DirectoryService.isLoggedByHRAdmin()
    ? [updatePasswordBtn, sendRequestPasswordBtn]
    : [updatePasswordBtn];
  console.log(buttonsConf);
  console.log(showError);
  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'PasswordSettings has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            <>
              {error && (
                <Alert
                  variant="warning"
                  className="mb-0 rounded-0"
                  onClose={() => setShowError(false)}
                >
                  <p>{error}</p>
                </Alert>
              )}
              <br />
              <FormGeneratorWithData
                data={data}
                buttonsConf={buttonsConf}
                onSubmit={handlePasswordSettingsFormSubmit}
                noValidate={validate}
              />
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

PasswordSettingsForm.propTypes = {
  profile: PropTypes.shape({
    identifier: PropTypes.any
  })
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    jsonSchema,
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any
};
export default PasswordSettingsForm;
