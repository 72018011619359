import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import BrightStaffLogin from 'components/app/common/BrightStaffLogin';
import LoginService from 'components/app/common/LoginService';
import { useState } from 'react';
import CompleteRegistration from './components/app/common/CompleteRegistration';
import withErrorHandling from './components/app/common/functions/withErrorHandling';
import AuthCallbackWithMicrosoft from 'components/app/common/AuthCallbackWithMicrosoft';
import { useMsal } from '@azure/msal-react';

const App = () => {
  const { accounts } = useMsal(); // Use the MSAL hook for Azure accounts
  const [showGetStartedForm, setShowGetStartedForm] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  // Function to handle the authentication check without using useEffect
  const checkUserAuthentication = async () => {
    if (!authChecked || accounts.length > 0) {
      if (accounts.length > 0) {
        console.log('Azure user is authenticated');
        await LoginService.processAzureAccount();
        setUserAuthenticated(true);
      } else {
        console.log('Checking non-Azure user authentication');
        const isLoggedIn = LoginService.isUserAuthenticated();
        if (!isLoggedIn) {
          console.log('User is not authenticated');
          setUserAuthenticated(false); // Ensure the user is marked as not authenticated
        } else {
          console.log('User is authenticated');
          setUserAuthenticated(true); // Set as authenticated for non-Azure users
        }
      }
      setAuthChecked(true);
    }
  };

  // Call the authentication check function immediately during rendering
  checkUserAuthentication();

  const handleShowRegistrationClick = () => {
    setShowGetStartedForm(!showGetStartedForm);
    window.location.href = 'https://codersden.uk/hr-software-buy-it-now/';
  };

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        {/* Azure login callback route */}
        <Route
          path="/authentication/azure/callback"
          element={<AuthCallbackWithMicrosoft />}
        />

        {/* Complete registration route */}
        <Route
          path="/authentication/complete-registration"
          exact
          element={<CompleteRegistration />}
        />

        {/* Main route for authorized users */}
        <Route
          path="/"
          element={
            userAuthenticated && !showGetStartedForm ? (
              <Layout /> // If the user is logged in (Azure or non-Azure), show the main layout
            ) : (
              <BrightStaffLogin
                showRegistration={handleShowRegistrationClick}
              /> // Otherwise, show the login page
            )
          }
        />

        {/* All other routes render the same Layout component */}
        <Route path="/*" element={<Layout />} />
      </Routes>
    </Router>
  );
};

export default withErrorHandling(App);
