const EquipmentService = {
  async saveEquipment(formData) {
    try {
      let url = '/api/equipment';
      let method = 'POST';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;

      if (formData.identifier !== undefined) {
        url = '/api/equipment/' + formData.identifier;
        method = 'PUT';
      }

      const updatedData = {
        ...formData,
        accountIdentifier: accountIdentifier,
        profileIdentifier: formData.profile.value
      };

      console.log(updatedData);

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
      });
      if (response.ok) {
        const newEquipment = await response.json();
        console.log('Equipment has been updated:', newEquipment);
      }
    } catch (error) {
      console.log('Error saving Equipment', error);
    }
  },
  async retrieveAllEquipmentByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/equipment/account/' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Equipment', error);
      throw error;
    }
  },
  async retrieveAllEquipmentsByProfile(profileIdentifier) {
    try {
      const response = await fetch(
        '/api/equipment/profile/' + profileIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Equipment', error);
      throw error;
    }
  },
  async retrieveEquipment(identifier) {
    try {
      const response = await fetch('/api/equipment/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Equipment', error);
      throw error;
    }
  },
  async deleteEquipment(identifier) {
    //TODO finisih this method.
    try {
      let url = '/api/<your_controller>' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newEquipment = await response.json();
        console.log('Equipment has been deleted:', newEquipment);
      }
    } catch (error) {
      console.log('Error deleting Equipment', error);
    }
  }
};
export default EquipmentService;
