import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import LoginService from 'components/app/common/LoginService';
import { useMsal } from '@azure/msal-react';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  const { instance, accounts } = useMsal();
  console.log(layout);
  const logout = async () => {
    try {
      await LoginService.logout(instance, accounts);
      window.location.href = '/'; // Redirect after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={logoutImg}
        alt="shield"
        width={100}
      />
      <TitleTag>See you again!</TitleTag>
      <p>
        Thanks for using Bright Staff Tool. You are{' '}
        <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        onClick={logout}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'simple',
  titleTag: 'h4'
};

export default LogoutContent;
