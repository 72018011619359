//import singleProfile from '../directory/data/single-profile.json';
import { useMsal } from '@azure/msal-react';
const LoginService = {
  isUserAuthenticated: function () {
    return localStorage.getItem('token') ? true : false;
  },
  async logoutCallBack() {
    let method = 'POST';

    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      },
      body: localStorage.getItem('token')
    };

    let url = '/api/login/logout';

    return await fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => {
        console.log(data);
        localStorage.clear();
        return data; // Pass the response data to the next `.then` block
      })
      .catch(error => {
        console.error('There was an error!', error);
        throw error; // Rethrow the error to be caught by the caller
      });
  },
  async logout(instance, accounts) {
    if (accounts.length > 0) {
      const logoutPopup = () => {
        instance
          .logoutPopup()
          .then(() => {
            console.log('User logged out');
            // You can handle any post-logout logic here, like clearing UI or redirecting manually
          })
          .catch(error => {
            console.error('Logout failed: ', error);
          });
      };
      await logoutPopup();
      await this.logoutCallBack();
    } else {
      await this.logoutCallBack();
    }
  },
  async login(userName, password) {
    let method = 'POST';

    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      },
      body: JSON.stringify({
        userName: userName,
        password: password
      })
    };
    let url = '/api/login';
    fetch(url, requestOptions).then(response => {
      if (!response.ok) throw new Error(response.status);
      return response.json();
    });
  },

  async getAzureAccessToken(instance, accounts) {
    try {
      const accessTokenRequest = {
        scopes: ['Calendars.Read'], // Permissions to access calendar events
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(accessTokenRequest);
      return response.accessToken; // Return the access token
    } catch (error) {
      console.error('Error acquiring token:', error);
    }
  },

  async loginWithAzure(userName) {
    let method = 'POST';

    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      }
    };
    let url = '/api/login/azure/' + userName;
    // Return the fetch promise
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(response.status);
    }
    return response; // Return the entire response object so it can be processed
  },
  async processAzureAccount() {
    console.log(1);
    const { instance, accounts } = useMsal();

    if (!accounts.length) {
      throw new Error('No accounts found.');
    }

    try {
      // Acquire the access token silently
      const accessTokenRequest = {
        scopes: ['User.Read'],
        account: accounts[0]
      };
      const tokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      const accessToken = tokenResponse.accessToken;

      // Fetch the user's profile from Microsoft Graph API
      const profileResponse = await fetch(
        'https://graph.microsoft.com/v1.0/me',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!profileResponse.ok) {
        throw new Error('Failed to fetch user profile');
      }
      const profile = await profileResponse.json();

      // Log in with the user's Azure email using your own backend service
      const loginResponse = await this.loginWithAzure(profile.mail);
      if (!loginResponse.ok) {
        throw new Error('Failed to log in with Azure');
      }
      const loginData = await loginResponse.json();

      // Store the token locally
      localStorage.setItem('token', loginData.token);

      // Fetch the user's profile using the token from your backend
      const profileUrl = `/api/profile/token/${loginData.token}`;
      const userProfileResponse = await fetch(profileUrl);
      if (!userProfileResponse.ok) {
        throw new Error('Failed to fetch user profile with token');
      }
      const userProfile = await userProfileResponse.json();

      // Store the profile in local storage
      localStorage.setItem('profile', JSON.stringify(userProfile));

      // Optionally redirect the user after successful login
      // window.location.href = '/';

      return true; // User successfully logged in
    } catch (error) {
      console.error('Error during Azure login process:', error);
      throw error; // Re-throw to handle the error further up if needed
    }
  }
};

export default LoginService;
